/**
 * All methods are static.
 */
import { toString } from "./HelperFunctions";

export class Logger {
  static Level = {
    NONE: "NONE",
    ERROR: "ERROR",
    WARN: "WARN",
    INFO: "INFO",
    DEBUG: "DEBUG",
    TRACE: "TRACE",
  };

  static Severity = {
    NONE: 0,
    ERROR: 1,
    WARN: 2,
    INFO: 3,
    DEBUG: 4,
    TRACE: 5,
  };

  static canPrint(severity) {
    return Logger.Severity[Logger.level] >= severity;
  }

  static error(message) {
    if (Logger.canPrint(Logger.Severity[Logger.Level.ERROR])) {
      console.error(Logger.Level.ERROR + ": " + toString(message));
    }
  }

  static warn(message) {
    if (Logger.canPrint(Logger.Severity[Logger.Level.WARN])) {
      console.warn(Logger.Level.WARN + ": " + toString(message));
    }
  }

  static info(message) {
    if (Logger.canPrint(Logger.Severity[Logger.Level.INFO])) {
      console.warn(Logger.Level.INFO + ": " + toString(message));
    }
  }

  static debug(message) {
    if (Logger.canPrint(Logger.Severity[Logger.Level.DEBUG])) {
      console.warn(Logger.Level.DEBUG + ": " + toString(message));
    }
  }

  static trace(message) {
    if (Logger.canPrint(Logger.Severity[Logger.Level.TRACE])) {
      console.warn(Logger.Level.TRACE + ": " + toString(message));
    }
  }
}

// Default level
Logger.level = Logger.Level.NONE;
