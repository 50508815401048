import { Logger } from "../commons/Logger";
import { toString } from "../commons/HelperFunctions";
import { sendAdSenseEvent } from "./AdSenseEvent";
import { EventType, ErrorCode, Settings } from "../commons/Constants";
import postEvent, { createBaseLanderEvent } from "../commons/LanderEvent";

// global event tracker object to store any variables needed for event publishing via callback
const eventTrackerObj = {};

/**
 * Store necessary properties in a tracker object.
 * @param landerConfig
 * @param queryConfig
 */
export function setupCallbackTracker(landerConfig, queryConfig) {
  eventTrackerObj.landerConfig = landerConfig;
  eventTrackerObj.queryConfig = queryConfig;
}

export function getCafStatus() {
  return eventTrackerObj.cafStatus ? eventTrackerObj.cafStatus : null;
}

/**
 * The 'pageLoadedCallback' parameter specifies a callback function to be called when the page has loaded or when a
 * request is blocked by Google.
 * Allows an action to be performed when the page has loaded or when a request is blocked by Google.
 * The callback function takes a boolean and a JSON object. The boolean is set to true when the request is accepted and
 * false when it is rejected by Google.
 * The JSON object contains the following fields:
 * - adult
 * - error_code
 * - faillisted
 * - faillist_reason
 * - client
 * - needsreview
 * - feed
 * - user_search
 * - query
 * @param {*} requestAccepted
 * @param {*} status
 */
export function cafReturnedWithClosure(handleRequestAcceptedState) {
  return function cafReturned(requestAccepted, status) {
    Logger.info(
      "caf returned: requestAccepted=" +
        requestAccepted +
        ", status=" +
        toString(status)
    );
    eventTrackerObj.cafStatus = status;

    handleRequestAcceptedState(requestAccepted);
    try {
      sendAdSenseEvent(
        eventTrackerObj.landerConfig,
        eventTrackerObj.queryConfig,
        status,
        requestAccepted
      );
    } catch (err) {
      Logger.error("unexpected error in google ads request=", +err);
    }
  };
}

/**
 * Callback function called by Adsense when the ads/RS request has completed
 * @param {string} containerName the container name that triggered the callback  "ads" or "relatedLinks"
 * @param {boolean} adsLoaded set to true when there is at least one ad/RS to display and false when there are no ads/RS returned.
 */
export function adsLoadedCallback(containerName, isAdsLoaded) {
  if (isAdsLoaded) {
    Logger.debug(`${containerName} are loaded`);
  } else {
    Logger.error(`No ${containerName} coverage from Google`);
    // Send no coverage error event
    const noAdsEvent = createBaseLanderEvent(
      EventType.ERROR,
      eventTrackerObj.landerConfig
    );
    if (containerName === "ads")
      noAdsEvent.errorCode = ErrorCode.ERROR_ADSENSE_NO_ADS;
    else noAdsEvent.errorCode = ErrorCode.ERROR_ADSENSE_NO_RS;
    postEvent(Settings.EVENT_PUBLISH_API, noAdsEvent);
  }
}
