import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import ParkingLander from "./ParkingLander";
import { setCookie } from "./commons/HelperFunctions";
import { LanderCookie } from "./commons/Constants";

if (process.env.NODE_ENV === "development") {
  // This is set by nginx in prod
  setCookie(LanderCookie.CLIENT_IP, "24.48.65.61");
}

const renderAppComponent = () => {
  const root = document.getElementById("contentMain");
  ReactDOM.render(<ParkingLander />, root);
};

renderAppComponent();
export { renderAppComponent };
