import React from "react";
import dompurify from "dompurify";
import { toTitleCase } from "../commons/HelperFunctions";
import PropTypes from "prop-types";

export const VerizonAds = ({ xmlResult }) => {
  const resultSet =
    xmlResult?.getElementById("adListings")?.getElementsByTagName("Listing") ??
    [];
  const verizonFeed = Array.from(resultSet);
  const searchKeyword =
    xmlResult?.getElementsByTagName("MetaData")[0]?.getAttribute("keywords") ??
    "";

  return (
    <div className="verizon-ads">
      <span style={{ fontSize: "small" }}>
        <span style={{ textDecoration: "underline" }}>Sponsored</span> Results
        for {toTitleCase(searchKeyword)}
      </span>
      <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
        {verizonFeed &&
          verizonFeed.map((item, itemIndex) => (
            <li
              key={`verizon-feed-ad-item-${itemIndex}`}
              style={{ padding: "24px 0px" }}
            >
              <a
                style={{
                  fontSize: "x-large",
                  textDecoration: "underline",
                  display: "block",
                  padding: "8px 0px",
                }}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  item.getElementsByTagName("ClickUrl")[0]?.childNodes[0]
                    ?.textContent
                }
                className="adLink"
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(item.getAttribute("title")),
                }}
              />
              <p
                style={{ margin: 0 }}
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(item.getAttribute("description")),
                }}
              />

              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`http://${item.getAttribute("siteHost")}`}
              >
                {item.getAttribute("siteHost")}
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

VerizonAds.propTypes = {
  xmlResult: PropTypes.object,
};
